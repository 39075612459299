<template>
	<v-dialog
		:value="value"
		max-width="500px"
		@keydown.esc="$emit('input', false)"
		persistent
		no-click-animation
	>
		<v-toolbar class="primary white--text">
			<span v-if="mode === 1">{{ $t("NewShippingAddress") }}</span>
			<span v-else>{{ $t("UpdateShippingAddress") }}</span><v-spacer></v-spacer>
					<v-btn icon dark @click="$emit('input', false)">
						<v-icon>close</v-icon>
					</v-btn>
		</v-toolbar>
	<AddEditShippingAddressCard ref="card" :mode="mode" :ShippingAddressId="ShippingAddressId" :UserId="UserId"
	@updated="$emit('updated', $event)"
	@cancel="$emit('input', false)"></AddEditShippingAddressCard>
	</v-dialog>
</template>

<script>
import AddEditShippingAddressCard from "./AddEditShippingAddressCard.vue";

export default {
	components: { AddEditShippingAddressCard },
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		ShippingAddressId: { type: Number },
		UserId: { type: String },
	},
	data() {
		return {
		};
	},
	watch: {
		value: async function (val) {
			if (!val) this.$refs.card.ClearForm();
		}
	},
	methods: {
	},
};
</script>
<i18n>
{
	"it": {
		"NewShippingAddress": "Nuovo indirizzo di consegna",
		"UpdateShippingAddress": "Aggiorna il tuo indirizzo di consegna",
		"formShippingAddress":{
			"DeliveryAddress": "Indirizzo di consegna",
			"AdditionalInfo": "Ulteriori informazioni",
			"AdditionalInfo_hint": "Codice, edificio, azienda",
			"AddressTitle": "Nome e cognome",
			"City": "Città",
			"Address": "Indirizzo",
			"PostalCode": "CAP",
			"CountryId": "Paese"
		}
	},
	"en": {
		"NewShippingAddress": "Add delivery address",
		"UpdateShippingAddress": "Update delivery address",
		"formShippingAddress":{
			"DeliveryAddress": "Delivery address",
			"AdditionalInfo": "Additional address information",
			"AdditionalInfo_hint": "Code, building, company",
			"AddressTitle": "Full name",
			"City": "City",
			"Address": "Registred address",
			"PostalCode": "Postal Code",
			"CountryId": "Country"
		}
	}
}
</i18n>
