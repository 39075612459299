<template>
	<v-card>
		<v-overlay :value="SendingOrder">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<h1 class="sgpshop-h1">{{ $t("pageTitle") }}</h1>
		<v-container fluid>
			<v-row>
				<v-col v-if="!BasketIsAllDigitalCopies">
					<AddEditShippingAddress v-model="deliveryAddressDialog.showDialog"
						:mode="deliveryAddressDialog.mode"
						:ShippingAddressId="deliveryAddressDialog.selectedShippingAddressId"
						@updated="ShippingAddressUpdated">
					</AddEditShippingAddress>
					<v-card v-if="shippingAddress">
						<v-card-title>
							{{ $t("sgpshop.deliveryAddress") }}
						</v-card-title>
						<v-card-text>
							<ShippingAddressView  :profile="shippingAddress"></ShippingAddressView>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn icon @click="ShowEditDeliveryAddressDialog(shippingAddress.ShippingAddressId)"><v-icon>fas fa-edit</v-icon></v-btn>
						</v-card-actions>
					</v-card>
					<v-card color="grey lighten-3" v-else>
						<v-card-text>
							<v-card-title>
								{{ $t("sgpshop.deliveryAddress") }}
							</v-card-title>
							Devi aggiungere un indirizzo di consegna
							<v-btn color="primary" large block @click="ShowNewDeliveryAddressDialog">
								<v-icon left>fas fa-plus</v-icon>{{ $t("common.add") }}</v-btn>

						</v-card-text>
					</v-card>
				</v-col>
				<v-col>
					<AddEditBillingProfile v-model="billingProfileDialog.showDialog"
						:mode="billingProfileDialog.mode"
						:BillingProfileId="billingProfileDialog.selectedProfileId"
						@updated="ProfileUpdated">
					</AddEditBillingProfile>
					<v-card v-if="billingProfile">
						<v-card-title>
							{{ $t("sgpshop.billingProfile") }}
						</v-card-title>
						<v-card-text>
							<UserBillingProfileView :profile="billingProfile"></UserBillingProfileView>
						</v-card-text>
						<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn icon @click="ShowEditBillingInfoDialog(billingProfile.BillingProfileId)"><v-icon>fas fa-edit</v-icon></v-btn>
							</v-card-actions>
					</v-card>
					<v-card color="grey lighten-3" v-else>
						<v-card-text>
							<v-card-title>
								{{ $t("sgpshop.billingProfile") }}
							</v-card-title>
							Devi aggiungere i tuoi dati di fatturazione
							<v-btn color="primary" large block @click="ShowNewBillingInfoDialog">
								<v-icon left>fas fa-plus</v-icon>{{ $t("common.add") }}</v-btn>

						</v-card-text>
						</v-card>
					</v-col>
			</v-row>
		</v-container>
			<v-card>
				<v-card-text>
					<v-container fluid fill-height>
						<v-row dense class="basketBottomBorder" v-if="$vuetify.breakpoint.mdAndUp">
								<v-col cols="3" class="basketHeader">{{ $t("sgpshop.artPiece") }}</v-col>
								<v-col cols="3" class="basketHeader">{{ $t("sgpshop.format") }}</v-col>
								<v-col cols="3" class="basketHeader">{{ $t("common.quantity") }}</v-col>
								<v-col cols="3" class="basketHeader text-right">{{ $t("common.total") }}</v-col>
						</v-row>
						<v-row  v-for="(basketItem, index) in Basket" :key="index" class="basketBottomBorder" >
							<v-col cols="12" md="3">
								<v-img
									class="rounded elevation-5"
									max-width="400" contain
									:src="basketItem.item.ImagePreviewUrl"></v-img>

							</v-col>
							<v-col cols="12" md="3">
								<h4>{{ basketItem.item['Name' + LanguageCodeSuffix] }}</h4>
								<span v-if="basketItem.version.GroupId !== 3">{{ $t("sgpshop.format") }}:</span> {{ basketItem.version['Name' + LanguageCodeSuffix] }}
								<span>{{ basketItem.version.Price | formatCurrency }}</span>
								<div v-if="basketItem.version.GroupId !== 3">
									{{ $t("sgpshop.finish") }}: {{ basketItem.selectedOption['Name' + LanguageCodeSuffix] }}
									<span v-if="basketItem.selectedOption.Price > 0">{{ basketItem.selectedOption.Price | formatCurrency }}</span>
								</div>
							</v-col>
							<v-col cols="12" md="3">
							<span class="basketPrice">{{ basketItem.quantity }}</span>
							</v-col>
							<v-col cols="12" md="3" class="text-right"><span class="basketSubtotal">
								{{ $t("sgpshop.itemsTotal") }}:
								{{ (basketItem.quantity * (basketItem.version.Price + basketItem.selectedOption?.Price )) | formatCurrency }}</span></v-col>
						</v-row>
						<v-row class="basketBottomBorder">
							<v-col cols="12" md="6" offset-md="6" class="text-right"><span class="basketSubtotal ">{{ $t("sgpshop.deliveryPriceLabel") }}: {{ DeliveryPriceTotal | formatCurrency }}</span></v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="6" offset-md="6" class="text-right">
								<span class="basketPrice ">{{ $t("common.total") }}: {{ BasketItemsTotal + DeliveryPriceTotal | formatCurrency }}</span>
								<br />
									<span>{{ $t("ivaInfo") }}  {{ (BasketItemsTotal + DeliveryPriceTotal) / 1.22 * 0.22 | formatCurrency }}
									</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col  cols="12">
								<v-checkbox v-model="IsGift" :label="$t('IsGift')"></v-checkbox>
								<v-textarea v-if="IsGift"
									:label="$t('GiftNote')"
									v-model="GiftNote"
									auto-grow
									:counter="500">
								</v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-checkbox v-model="termsAgreed">
									<template v-slot:label>
										<div>
											<p>La invitiamo a controllare e confermare il suo ordine di acquisto e con la conferma lei accetta
											integralmente <a @click.stop.prevent="showTermsDialog = true">
													le condizioni generali</a>, e che potranno
											essere stampate o salvate, nel quale trova riportate le informazioni relative al venditore nonché
											alle condizioni di acquisto.
											<br />
											Le ricordiamo che il diritto di recesso è escluso per tale acquisto trattandosi di beni digitali o di
											stampe su richiesta.
											<br />
											Pertanto accettando il presente ordine sarà tenuto al pagamento della somma qui di seguito
											riportata</p>
											<v-dialog v-model="showTermsDialog" scrollable max-width="80%">
												<v-card>
													<v-card-text class="pt-0">
														<v-row>
															<v-col cols="12" class="text-justify" style="overflow-y: auto">
																<TermsConditions></TermsConditions>
															</v-col>
														</v-row>
													</v-card-text>
													<v-card-actions>
														<v-spacer></v-spacer>
														<v-btn color="primary" @click="showTermsDialog = false">{{
															$t("common.close")
														}}</v-btn>
													</v-card-actions>
												</v-card>
											</v-dialog>
										</div>
									</template>
								</v-checkbox>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<div v-if="billingProfile == null || (!BasketIsAllDigitalCopies && shippingAddress == null) || !termsAgreed"
				class="ma-5 pb-5">
					<v-alert type="warning">
						<div v-if="billingProfile == null">
							{{ $t("missingBillingInfo") }}
						</div>
						<div v-if="shippingAddress == null">
							{{ $t("missingDeliveryAddress") }}
						</div>
						<div v-if="termsAgreed === false">
								{{ $t("termsAndConditionsRequired") }}
							</div>
					</v-alert>

					<v-btn :disabled="true" large block>{{ $t("payWithPayPal") }}</v-btn>
				</div>
				<div v-else>
					<div class="pa-5">
						<PayPalButton
							:amount="PayPalAmount"
							currency="EUR"
							:client="PayPal.credentials"
							:env="PayPal.enviroment"
							@paymentCompleted="OnPaymentComplete($event)"></PayPalButton>
					</div>
				</div>
			</v-card>
	</v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import ShippingAddressView from "@/components/Account/ShippingAddressView";
import AddEditShippingAddress from "@/components/Account/AddEditShippingAddress";
import UserBillingProfileView from "@/components/Account/UserBillingProfileView";
import PayPalButton from "@/components/Shared/FormControl/PayPalButton";
import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile";
import TermsConditions from "../StaticContent/TermsOfUse";

export default {
	components: {
		UserBillingProfileView,
		ShippingAddressView,
		AddEditShippingAddress,
		PayPalButton,
		AddEditBillingProfile,
		TermsConditions
	},
	computed: {
		...mapGetters([
			"Basket",
			"BasketItemsTotal",
			"DeliveryPriceTotal",
			"BasketIsAllDigitalCopies"
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		PaymentAmount() {
			return this.BasketItemsTotal + this.DeliveryPriceTotal
		},
		PayPalAmount() {
			return (Math.round(this.PaymentAmount * 100) / 100).toFixed(2);
		}
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.basket"),
					disabled: true,
					exact: true,
					to: "/Basket",
				}],
			shippingAddress: null,
			billingProfile: null,
			showAddShippingAddress: false,
			showAddBillingProfile: false,
			PayPal: {
				enviroment: process.env.VUE_APP_PAYPAL_ENV,
				credentials: {
					sandbox: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENTID,
					production: process.env.VUE_APP_PAYPAL_PRODUCTION_CLIENTID,
				},
				style: {
					label: "checkout",
					size: "responsive",
					shape: "rect",
					color: "blue",
				},
				order: {
					description: "",
					amount: {
						currency_code: "EUR",
						value: 0,
					},
				},
			},
			billingProfileDialog: {
				mode: 1,
				selectedProfileId: null,
				showDialog: false
			},
			deliveryAddressDialog: {
				mode: 1,
				selectedShippingAddressId: null,
				showDialog: false
			},
			SendingOrder: false,
			showTermsDialog: false,
			termsAgreed: false,
			IsGift: false,
			GiftNote: null
		};
	},
	watch: {
		"billingProfile.showDialog": {
			handler: function (val) {
				if (!val) {
					this.billingProfileDialog.selectedProfileId = null;
				}
			}
		},

		"deliveryAddressDialog.showDialog": {
			handler: function (val) {
				if (!val) {
					this.deliveryAddressDialog.selectedShippingAddressId = null;
				}
			}
		}
	},
	async mounted() {
		await this.LoadBillingAddress();
		await this.LoadShippingAddress();
	},
	created() {
		this.OrdersService = new CrudClient("Shop/ShopClients/Orders");
		this.BillingProfileService = new CrudClient(
			"SGP/Client/BillingProfilesSelf"
		);

		this.ShippingAddressesService = new CrudClient(
			"Shop/ShopClients/ShippingAddresses"
		);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "ResetBasket"]),

		ShowNewBillingInfoDialog() {
			this.billingProfileDialog.mode = 1;
			this.billingProfileDialog.selectedProfileId = null;
			this.billingProfileDialog.showDialog = true;
		},

		ShowEditBillingInfoDialog(profileId) {
			this.billingProfileDialog.mode = 2;
			this.billingProfileDialog.selectedProfileId = profileId;
			this.billingProfileDialog.showDialog = true;
		},

		async ProfileUpdated() {
			this.billingProfileDialog.mode = 1;
			this.billingProfileDialog.selectedProfileId = null;
			this.billingProfileDialog.showDialog = false;

			await this.LoadBillingAddress();
		},

		ShowNewDeliveryAddressDialog() {
			this.deliveryAddressDialog.mode = 1;
			this.deliveryAddressDialog.selectedShippingAddressId = null;
			this.deliveryAddressDialog.showDialog = true;
		},

		ShowEditDeliveryAddressDialog(profileId) {
			this.deliveryAddressDialog.mode = 2;
			this.deliveryAddressDialog.selectedShippingAddressId = profileId;
			this.deliveryAddressDialog.showDialog = true;
		},

		async ShippingAddressUpdated() {
			this.deliveryAddressDialog.mode = 1;
			this.deliveryAddressDialog.selectedShippingAddressId = null;
			this.deliveryAddressDialog.showDialog = false;

			await this.LoadShippingAddress();
		},

		async OnPaymentComplete(event) {
			// this.$log.debug("Payment Complete");
			// this.$log.debug(event);
			await this.SubmitOrder(event);
		},
		async SubmitOrder(event) {
			try {
				this.SendingOrder = true;

				const orderDto = {
					BillingProfileId: this.billingProfile.BillingProfileId,
					ShippingAddressId: this.BasketIsAllDigitalCopies ? null : this.shippingAddress.ShippingAddressId,
					GiftNote: this.GiftNote,
					IsGift: this.IsGift
				}

				const orderId = await this.OrdersService.Post(null, orderDto, null, true);
				this.$log.debug(orderId);
				for (let i = 0; i < this.Basket.length; i++) {
					const basketItemDto = {
						VersionId: this.Basket[i].version.VersionId,
						Quantity: this.Basket[i].quantity,
						SelectedOptionId: this.Basket[i].selectedOption?.OptionId,
						SelectedOptionPrice: this.Basket[i].selectedOption?.Price,
					};
					const orderItemId = await this.OrdersService.Post(orderId, basketItemDto, "OrderItem", true);
					this.$log.debug(orderItemId + ": item inserted");
				}

				// SendOrder order with Paypal Id
				await this.OrdersService.Post(orderId, null, "SendOrder?PayPalId=" + event.id, false);
				this.$log.debug("Order: " + orderId + " Submitted");
				this.ResetBasket();

				this.$router.push(`/ShopClients/Orders/${orderId}?from=CompleteOrder`)
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.SendingOrder = false;
			}
		},

		async LoadBillingAddress() {
			const res = await this.BillingProfileService.GetPaged({
				limit: 0,
				fields: "*, ItalianCity.Name, Country.DescrizioneNazione",
				filter: "IsActive:true"
			});

			if (res.Data.length > 0) {
				this.billingProfile = res.Data[0];
			}
		},

		async LoadShippingAddress() {
			const shippingRes = await this.ShippingAddressesService.GetPaged({
				limit: 0,
				fields: "*, Country.DescrizioneNazione",
				filter: "IsActive:true"
			});

			if (shippingRes.Data.length > 0) {
				this.shippingAddress = shippingRes.Data[0];
			}
		}
	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Completa l'ordine",
			"payWithPayPal": "PayPal Paga adesso",
			"missingBillingInfo": "Manca l'indirizzo di fatturazione",
			"missingDeliveryAddress": "Manca l'indirizzo di consenga",
			"termsAndConditionsRequired": "Per procedere l'ordine devi accettare le condizioni generali",
			"IsGift": "Questo ordine è un regalo?",
			"GiftNote": "Messaggio di auguri",
			"ivaInfo": "Di cui IVA 22%"
		},
		"en":{
			"pageTitle": "Complete Order",
			"payWithPayPal": "PayPal Pay Now",
			"missingBillingInfo": "Billing Info is required",
			"missingDeliveryAddress": "Delivery Address is required",
			"termsAndConditionsRequired": "You have to agree with the terms of use",
			"IsGift": "Is this order a gife?",
			"GiftNote": "Your message",
			"ivaInfo": "Including 22% VAT"
		}
	}
</i18n>
