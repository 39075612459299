<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
						<v-icon slot="divider" class="secondary--text text--lighten-2"
							>fas fa-caret-right</v-icon
						>
		</v-breadcrumbs>

		<h1 class="sgpshop-h1">{{ $t("sgpshop.orders") }}</h1>

		<v-progress-linear v-if="loadingData" indeterminate></v-progress-linear>
		<div v-else>

<v-alert type="info" v-if="orders && orders.length === 0">
	Nessun ordine trovato
</v-alert>

			<v-card v-for="order in orders" :key="order.OrderId" class="">
			<v-card-text>
				<span class="text-overline">
				{{ $t("sgpshop.order") }} Id {{ order.OrderId }} del  {{ order.OrderTime | formatLocalDate }}</span>
				<br />
				<v-chip	dark label color="primary darken-2" class="my-2">
					{{ $t("sgpshop.orderStatus") }}: {{ order.ShopOrderStatus['Name' + LanguageCodeSuffix] }}
				</v-chip>

				<v-img v-for="item in order.ShopOrderItems" :key="item.ItemId"
							class="rounded elevation-5"
							max-width="300" contain
							:src="item.ShopItemVersion.ShopItem.ImagePreviewUrl">
				</v-img>

			</v-card-text>
			<v-card-actions>
				<span class="basketPrice">
					{{ $t("common.total") }}: {{ order.Total | formatCurrency }}
				</span>
				<v-spacer></v-spacer>
				<v-btn :to="`/ShopClients/Orders/${order.OrderId}`" large color="primary">{{$t("orderDetails")}}</v-btn>
			</v-card-actions>
		</v-card>

		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	computed: {
		...mapGetters([]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		}
	},
	metaInfo() {
		return {
			title: this.$t("sgpshop.orders")
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.shopClients"),
					disabled: false,
					exact: true,
					to: "/ShopClients",
				},
				{
					text: this.$t("sgpshop.orders"),
					disabled: true,
					exact: true,
					to: "/ShopClients/Orders",
				}],
			orders: null,
			loadingData: false
		};
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
	},
	async mounted() {
		try {
			this.loadingData = true;
			const res = await this.OrdersService.GetPaged({
				limit: 0,
				fields: "*, ShopOrderItems.*, ShopOrderStatus.*, ShopOrderItems.ShopItemVersion.ShopItem.*",
				orderBy: "OrderId:desc"
			});

			this.orders = res.Data;
		} catch (error) {
			this.snackError({ Text: this.$t("snackError_CannotLoadData") });
			this.$captureError(error);
		} finally {
			this.loadingData = false;
		}
	},
	created() {
		this.OrdersService = new CrudClient("Shop/ShopClients/Orders");
	}
}
</script>
<i18n>
	{
		"it":{
			"orderDetails": "Dettaglio ordine"

		},
		"en":{
			"orderDetails": "Order Details"
		}
	}
</i18n>
