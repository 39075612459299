<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
						<v-icon slot="divider" class="secondary--text text--lighten-2"
							>fas fa-caret-right</v-icon
						>
		</v-breadcrumbs>
		<v-alert type="success" v-if="$route.query.from==='CompleteOrder'">
			<span>
				Grazie per il tuo ordine!
			</span>
		</v-alert>
		<div v-if="orderDetails">
			<h1 class="sgpshop-h2">{{ $t("sgpshop.order") }} {{ OrderId }} del  {{ orderDetails.OrderTime | formatLocalDate }} </h1>
			<v-chip dark label color="primary darken-2" class="my-2">
				{{ $t("sgpshop.orderStatus") }}: {{ orderDetails.ShopOrderStatus['Name' + LanguageCodeSuffix] }}
			</v-chip>

			<v-card class="mb-4">
					<v-container fluid fill-height>
						<v-row class="basketBottomBorder" v-if="$vuetify.breakpoint.mdAndUp">
								<v-col cols="4" class="basketHeader">{{ $t("sgpshop.artPiece") }}</v-col>
								<v-col cols="4" class="basketHeader">{{ $t("sgpshop.format") }}</v-col>
								<v-col cols="2" class="basketHeader">{{ $t("common.quantity") }}</v-col>
								<v-col cols="2" class="basketHeader text-right">{{ $t("common.total") }}</v-col>
						</v-row>
						<v-row v-for="(orderItem, index) in orderDetails.ShopOrderItems" :key="index" class="basketBottomBorder" >
							<v-col cols="12" md="4">
								<v-img
									class="rounded elevation-5"
									max-width="400" contain
									:src="orderItem.ShopItemVersion.ShopItem.ImagePreviewUrl"></v-img>

							</v-col>
							<v-col cols="12" md="4">
								<h4>{{ orderItem.ShopItemVersion.ShopItem['Name' + LanguageCodeSuffix] }}</h4>
								{{ $t("sgpshop.format") }}: {{ orderItem.ShopItemVersion['Name' + LanguageCodeSuffix] }}
								<!-- - {{ orderItem.ShopItemVersion.Height }} x {{ orderItem.ShopItemVersion.Width }} cm -->
								<br />
								{{$t("common.price")}}: <span>{{ orderItem.Price | formatCurrency }}</span>
								<div v-if="orderItem.ShopItemVersion.GroupId !== 3 && orderItem.ShopItemVersionOption">
								{{ $t("sgpshop.finish") }}: {{ orderItem.ShopItemVersionOption['Name' + LanguageCodeSuffix]  }}
									<span v-if="orderItem.ShopItemVersionOption.Price > 0">
										{{ orderItem.ShopItemVersionOption.Price  | formatCurrency }}</span>
								</div>
							</v-col>
							<v-col cols="12" md="2">
								<span v-if="$vuetify.breakpoint.smAndDown">{{ $t("common.quantity") }}: </span>
								<span class="basketPrice">{{ orderItem.Quantity }}</span>
							</v-col>
							<v-col cols="12" md="2" class="text-right">
								<span class="basketSubtotal">
									{{ $t("sgpshop.itemsTotal") }}
									{{ (orderItem.Quantity * (orderItem.Price + orderItem.SelectedOptionPrice)) | formatCurrency }}</span>
							</v-col>
						</v-row>
						<!-- <v-row>
							<v-col cols="12" md="6" offset-md="6" class="text-right"><span class="basketPrice ">{{ $t("common.basketTotal") }}: {{ orderDetails.ItemsTotal | formatCurrency }}</span></v-col>
						</v-row> -->
						<v-row class="basketBottomBorder">
								<v-col cols="12" md="6" offset-md="6" class="text-right"><span class="basketSubtotal">{{ $t("shippingPrice") }}: {{ orderDetails.ShippingCost | formatCurrency }}</span></v-col>
							</v-row>
						<v-row>
							<v-col cols="12" md="6" offset-md="6" class="text-right"><span class="basketPrice ">{{ $t("common.total") }}: {{ orderDetails.Total | formatCurrency }}</span></v-col>
						</v-row>
						<v-row v-if="orderDetails.IsGift">
							<v-col>
								<v-alert color="yellow lighten-3" icon="fas fa-gift">
									{{ orderDetails.GiftNote }}
								</v-alert>
							</v-col>
						</v-row>
					</v-container>
			</v-card>
			<v-container fluid>
				<v-row>
					<v-col cols="12" md="6"
					v-if="orderDetails.ShippingAddressId"
					:class="{'pl-0': $vuetify.breakpoint.mdAndUp,
							'pa-0': $vuetify.breakpoint.smAndDown }">
						<v-card class="mb-4">
							<v-card-title>{{ $t("yourShippingAddress") }}</v-card-title>
							<v-card-text>
								<ShippingAddressView :profile="orderDetails.ShippingAddress"></ShippingAddressView>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" md="6" :class="{ 'pr-0': $vuetify.breakpoint.mdAndUp,
				'pa-0': $vuetify.breakpoint.smAndDown }">
						<v-card class="mb-4">
							<v-card-title>{{ $t("yourBillingAddress") }}</v-card-title>
								<v-card-text>
									<UserBillingProfileView  :profile="orderDetails.BillingProfile"></UserBillingProfileView>
								</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import UserBillingProfileView from "@/components/Account/UserBillingProfileView";
import ShippingAddressView from "@/components/Account/ShippingAddressView";
export default {
	components: {
		UserBillingProfileView, ShippingAddressView
	},
	computed: {
		...mapGetters([
		]),
		LanguageCodeSuffix() {
			return this.$root.$i18n.locale.toUpperCase();
		},
		OrderId() {
			return this.$route.params.OrderId;
		}
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle") + this.OrderId,
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.shopClients"),
					disabled: false,
					exact: true,
					to: "/ShopClients",
				},
				{
					text: this.$t("sgpshop.orders"),
					disabled: false,
					exact: true,
					to: "/ShopClients/Orders",
				}],

			orderDetails: null,
			loadingData: false
		};
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.OrderId,
			disabled: false,
			exact: true,
			to: `/ShopClients/Orders/${this.OrderId}`,
		});

		try {
			this.loadingData = true;
			this.orderDetails = await this.OrdersService.GetSelectedFields(this.OrderId,
				`*, ShopOrderItems.*, ShopOrderStatus.*,
				ShopOrderItems.ShopItemVersion.*, ShopOrderItems.ShopItemVersion.ShopItem.*,
				ShopOrderItems.ShopItemVersionOption.*, 
				ShippingAddress.*, ShippingAddress.Country.*, 
				BillingProfile.*, BillingProfile.ItalianCity.*, BillingProfile.Country.*`);
		} catch (error) {
			this.snackError({ Text: this.$t("snackError_CannotLoadData") });
			this.$captureError(error);
		} finally {
			this.loadingData = false;
		}
	},
	created() {
		this.OrdersService = new CrudClient("Shop/ShopClients/Orders");
	}
}
</script>
<i18n>
	{
		"it":{
			"pageTitle": "Dettaglio ordine Id ",
			"yourBillingAddress": "Dati di fatturazione",
			"yourShippingAddress": "Indirizzo di consegna",
			"shippingPrice": "Spedizione"
		},
		"en":{
			"pageTitle": "Summary of the order Id ",
			"yourBillingAddress": "Your billing address",
			"yourShippingAddress": "Your delivery address",
			"shippingPrice": "Delivery"
		}
	}
</i18n>
