<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
						<v-icon slot="divider" class="secondary--text text--lighten-2"
							>fas fa-caret-right</v-icon
						>
		</v-breadcrumbs>

		<h1 class="sgpshop-h1">{{ $t("sgpshop.shopClients") }}</h1>
		<v-card class="ma-5 pa-5">
			<v-container>
				<v-row>
					<v-col cols="12" md="4">
						<v-card to="/ShopClients/Orders" dark class="blue darken-3 text-center pa-5">
							<v-card-text>
								<v-icon size="40">far fa-receipt</v-icon>
									<h5 class="text-h5 mt-3">I miei ordini</h5>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" md="4">
						<v-card to="/Account/Profile" dark class="blue darken-3 text-center pa-5">
								<v-card-text>
									<v-icon size="40">fas fa-address-card</v-icon>
										<h5 class="text-h5 mt-3">I miei dati</h5>
								</v-card-text>
							</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>
<script>
export default {
	metaInfo() {
		return {
			title: this.$t("sgpshop.shopClients")
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: this.$t("sgpshop.shopClients"),
					disabled: true,
					exact: true,
					to: "/ShopClients",
				}]
		}
	},
	mounted() {

	},
	methods: {

	}
}
</script>
<i18n>
	{
		"it":{
		},
		"en":{

		}
	}
</i18n>
