<template>
	<div ref="containerDiv"></div>
</template>

<script>
export default {
	data() {
		return {
			pp_button: null,
		};
	},

	props: {
		amount: {
			type: String,
			required: true,
		},

		currency: {
			type: String,
			default: "EUR",
		},

		// order: {
		// 	type: Object,
		// 	required: true,
		// },

		client: {
			type: Object,
			required: true,
		},

		env: {
			type: String,
			required: true,
		},

		buttonStyle: {
			type: Object,
			default: function () {
				return {
					label: "checkout",
					size: "responsive",
					layout: "horizontal",
					shape: "rect",
					color: "blue",
				};
			},
		},
	},

	async mounted() {
		await this.$loadScript(
			`https://www.paypal.com/sdk/js?locale=it_IT&currency=${
				this.currency
			}&client-id=${this.client[this.env]}`
		);

		this.$log.debug("Script Loaded");

		this.pp_button = window.paypal.Buttons({
			style: this.buttonStyle,
			createOrder: (data, actions) => {
				return actions.order.create({
					purchase_units: [
						{
							amount: {
								currency_code: this.currency,
								value: this.amount,
							},
						},
					],
				});
			},
			onApprove: async (data, actions) => {
				this.$log.debug("Transaction approved");
				this.$log.debug(data);

				const details = await actions.order.capture();
				this.$log.debug(
					"Transaction completed by " + details.payer.name.given_name
				);

				this.$emit("paymentCompleted", details);
			},
			onError: (err) => {
				this.$log.error(err);
				this.$emit("onError", err);
			},
		});

		this.pp_button.render(this.$refs.containerDiv);
	},

	async beforeDestroy() {
		if (this.pp_button) {
			await this.pp_button.close();
			this.$log.debug("Button closed");
		}
	},
};
</script>

<style >
.paypal-buttons iframe {
	z-index: 1;
}
</style>
